<template>
  <div>
    <b-overlay
      :show="getLoadingState"
      :fixed="false"
      :no-wrap="true"
      opacity="0.6"
      spinner-type="border"
      spinner-variant="primary"
      variant="black"
    />
  </div>
</template>

<script>
import { BOverlay } from "bootstrap-vue";
import { mapGetters } from 'vuex'
export default {
  components: {
    BOverlay,
  },
	computed:{
		...mapGetters(['getLoadingState'])
	}
};
</script>

<style lang="scss" scoped>
</style>